const initialState = {
  examPackage: {},
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

const CourseExamDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_COURSE_EXAM_DETAIL":
      return {
        ...state,
        loading: true,
      };

    case "FETCHING_COURSE_EXAM_DETAIL_SUCCESS":
      return {
        examPackage: payload,
        message: {
          success: "All examPackages fetched", 
          error: null,
        },
        loading: false,
      };
 
    case "FETCHING_COURSE_EXAM_DETAIL_ERROR":
      return {
        examPackage: {},
        message: {
          success: null,
          error: payload,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default CourseExamDetailReducer;
