const initialState = {
  boughtCourse: [],
  loading: true,
  message: {
    success: null,
    error: null,
  },
};

export const fetchBoughtCourseReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case "BOUGHT_COURSE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "BOUGHT_COURSE_SUCCESS":
      return {
        boughtCourse: payload,
        loading: false,
        message: {
          success: "Bought course fetched successfully",
          error: null,
        },
      };

    case "BOUGHT_COURSE_FAILURE":
      return {
        data: {},
        laoding: false,
        message: {
          success: null,
          error: payload,
        },
      };

    default:
      return state;
  }
};
