import { applyMiddleware, createStore } from "redux";
import allReducers from "./reducers/combineReducer";
import ThunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { save, load } from "redux-localstorage-simple";
 
const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(
    ThunkMiddleware,
    save({ states: ["login", "courses", "courseVideos", "courseNotes"] }) // Saving done here
  )
)(createStore);

const Store = createStoreWithMiddleware(
  allReducers,
  load({ states: ["login", "courses", "coursesVideos", "courseNotes"] }) // Loading done here
);

export default Store;
