const initialState = {
  data: [],
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

export const NewsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "NEWS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "NEWS_REQUEST_SUCCESS":
      return {
        data: payload,
        message: {
          success: "news successfully fetched",
          error: null,
        },
        loading: false,
      };

    case "NEWS_REQUEST_ERROR":
      return {
        data: payload,
        message: {
          error: "An error occurred",
          success: null,
        },
        loading: false,
      };

    case "FETCH_SINGLE_NEWS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_SINGLE_NEWS_SUCCESS":
      return {
        data: payload,
        message: {
          success: "single news fetched successfully",
          error: null,
        },
        loading: false,
      };

    case "FETCH_SINGLE_NEWS_ERROR":
      return {
        data: payload,
        message: {
          error: "An error occurred while fetching single news",
          success: null,
        },
        loading: false,
      };

    default:
      return state;
  }
};
