const initialState = {
  loading: false,
  discussion_forums: [],
  message: {
    success: null,
    error: null,
  },
};

const DiscussionForumReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_DISCUSSION_FORUM":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_DISCUSSION_FORUM_SUCCESS":
      return {
        ...state,
        loading: false,
        discussion_forums: payload,
        message: {
          success: "Discussion_Forums has been fetched successfully",
          error: null,
        },
      };

    case "FETCHING_DISCUSSION_FORUM_ERROR":
      return {
        ...state,
        loading: false,
        message: {
          success: null,
          error: "Failed to fetch discussion_forum",
        },
      };

    default:
      return state;
  }
};

export default DiscussionForumReducer;
