const initialState = {
    data:[],
    message:{
        success:"",
        error:""
    },
    loading:false
}

export const testimonialReducer = (state = initialState , {type, payload}) => {
    switch(type) {
        case "TESTIMONIAL_REQUEST" : 
        return {
            ...state,
            loading:true
        }

        case "TESTIMONIAL_SUCCESS" : 
        return {
            payload:payload,
            message:{
                success:"testimonial successfully fetched",
                error:null
            },
            loading:false
        }

        case "TESTIMONIAL_FAILURE" : 
        return {
            payload:[],
            message:{
                success:null,
                error:"Error Occured"
            }
        }

        default :
        return state;
    }
}