const initialState = {
  loading: false,
  videos: [],
  message: {
    success: null,
    error: null,
  },
  singleVideo: {},
};

const VideoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_VIDEO":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_VIDEO_SUCCESS":
      return {
        ...state,
        loading: false,
        videos: payload,
        message: {
          success: "Videos has been fetched successfully",
          error: null,
        },
      };

    case "FETCHING_VIDEO_ERROR":
      return {
        ...state,
        loading: false,
        message: {
          success: null,
          error: "Failed to fetch video",
        },
      };

    case "FETCHING_SINGLE_VIDEO":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_SINGLE_VIDEO_SUCCESS":
      return {
        ...state,
        message: {
          success: "Video has been fetched successfully",
          error: null,
        },
        singleVideo: payload,
        loading: false,
      };

    case "FETCHING_SINGLE_VIDEO_ERROR":
      return {
        ...state,
        loading: false,
        message: {
          success: null,
          error: "Failed to fetch video",
        },
      };

    default:
      return state;
  }
};

export default VideoReducer;
