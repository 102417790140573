const initialState = {
  message: {
    error: null,
    success: null,
  },
  loading: false,
};

const PasswordResetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_LINK_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "RESET_LINK_SUCCESS":
      return {
        ...state,
        loading: false,
        message: {
          error: null,
          success: payload,
        },
      };

    case "RESET_LINK_FAILURE":
      return {
        ...state,
        loading: false,
        message: {
          error: payload,
          success: null,
        },
      };

    default:
      return state;
  }
};

export default PasswordResetReducer;
