const initialState = {
  books: [],
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

const BookReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_BOOK":
      return {
        ...state,
        loading: true,
      };

    case "FETCHING_BOOK_SUCCESS":
      return {
        books: payload,
        message: {
          success: "All books fetched",
          error: null,
        },
        loading: false,
      };

    case "FETCHING_BOOK_ERROR":
      return {
        books: [],
        message: {
          success: null,
          error: payload,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default BookReducer;
