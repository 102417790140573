const initialState = {
  banners: [],
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

const BannerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_BANNER":
      return {
        ...state,
        loading: true,
      };

    case "FETCHING_BANNER_SUCCESS":
      return {
        banners: payload,
        message: {
          success: "All examPackages fetched",
          error: null,
        },
        loading: false,
      };

    case "FETCHING_BANNER_ERROR":
      return {
        banners: [],
        message: {
          success: null,
          error: payload,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default BannerReducer;
