const initialState = {
  examPackages: [],
  message: {
    success: null,
    error: null,
  },
  loading: false,
};

const CourseExamReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCHING_COURSE_EXAM_PACKAGE":
      return {
        ...state,
        loading: true,
      };

    case "FETCHING_COURSE_EXAM_PACKAGE_SUCCESS":
      return {
        examPackages: payload,
        message: {
          success: "All examPackages fetched",
          error: null,
        },
        loading: false,
      };

    case "FETCHING_COURSE_EXAM_PACKAGE_ERROR":
      return {
        examPackages: [],
        message: {
          success: null,
          error: payload,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default CourseExamReducer;
