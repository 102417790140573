const initialState = {
  isLoading: false,
  error: null,
  success: null,
  errorMessage: null,
  successMessage: null,
};

export const Register = (state = initialState, action) => {
  switch (action.type) {
    case "REGISTERING":
      return {
        ...state,
        isLoading: true,
      };

    case "REGISTER_SUCCESS":
      return {
        ...state,
        isLoading: false,
        success: true,
        successMessage: action.payload,
        error: false,
        errorMessage: null,
      };

    case "REGISTER_FAIL":
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload,
        success: false,
        successMessage: null,
      };

    default:
      return state;
  }
};
